import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import styles from './Hero.module.sass'
import Particles from 'react-tsparticles';

const Hero = ({
  tagline
}) => (
  <div className={styles['hero']}>
    <Particles
    id={styles['tsparticles']}
    width="100vw"
    height="75vh"
    options={{
      background: {
        color: {
          value: '#0B4F6C',
        },
      },
      fpsLimit: 60,
      interactivity: {
        detectsOn: "canvas",
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 2,
            opacity: 0.8,
            size: 40,
          },
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        collisions: {
          enable: true,
        },
        move: {
          direction: "none",
          enable: true,
          outMode: "bounce",
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            value_area: 800,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          random: true,
          value: 5,
        },
      },
      detectRetina: true,
    }}
  />
    <div className={styles['hero__header']}>
    <img src="/logos/white-logo.png" className={styles['hero__header__logo']} />
      <img src="/img/partner-logos/GC-Partner-no_outline-H.png" className={styles['hero__header__cloud_logo']} />
                <h1 className={styles['hero__header__title']}>
                  {tagline}
                </h1>
    </div>
</div>
)

Hero.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string
}

export default Hero

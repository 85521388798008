import React from 'react'
import PropTypes from 'prop-types'
import styles from './Offerings.module.sass'

const Offerings = ({ gridItems }) => (
  <div className='columns is-multiline'>
    {gridItems.map(item => (
      <div key={item.image} className='column is-6' style={{ borderRadius: '5px' }}>
        <section className='section'>
            <img alt='' src={item.image} className={styles['offerings__img']} />
            <h3 className={styles['offerings__title']}>{item.title}</h3>
          <p className={styles['offerings__text']}>{item.text}</p>
        </section>
      </div>
    ))}
  </div>
)

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
    })
  ),
}

export default Offerings
